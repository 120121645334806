import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Recaptcha from "./ReCaptcha";
import { useForm } from "react-hook-form";
import {
  textValidation,
  emailValidation,
  numberValidation,
} from "./Validation";
import getPayload from "./getPayload";
import { getService } from "../../shared/components/restApi";

function RequestForm(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [includeHouseHold, setIncludeHouseHold] = useState(false);
  const [requestSubmittedFor, setRequestSubmittedFor] = useState(false);
  const [isPSCUMember, setisPSCUMember] = useState(false);
  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    requestType,
  } = props;

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const getResponseToken = (token) => {
    setRecaptchaToken(token);
  };

  const { register, handleSubmit, formState, errors, validate } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    // submitted Data
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);

      // payload
      const inputsForPayLoad = {
        request: requestType,
        inputsData: sendRequest,
        configValues,
        recaptchaToken,
      };

      const payload = getPayload(inputsForPayLoad);

      // API request

      const url = process.env.REACT_APP_CURRENT_ENVIRONMENT_URL;

      const submitOptions = {
        method: `POST`,
        url,
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  const householdHandle = (event) => {
    if (event.target.value === "Yes") {
      setIncludeHouseHold(true);
    } else {
      setIncludeHouseHold(false);
    }
  };

  const handleRequestFor = (event) => {
    if (event.target.value === "Yes") {
      setRequestSubmittedFor(true);
    } else {
      setRequestSubmittedFor(false);
    }
  };

  const handlePSCUMember = (event) => {
    if (event.target.value === "Yes") {
      setisPSCUMember(true);
    } else {
      setisPSCUMember(false);
    }
  };

  return (
    <div className="main-content">
      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          <div className="form-title mb-3">
            <Card.Text>
              If you are not the Consumer making the request but rather an
              authorized agent of the Consumer, please state your full name and
              enter you email address. (References to “you” or “your” mean
              Consumer, not the Consumer's authorized agent.)
            </Card.Text>
            <Card.Text>
              <span className="text-danger">*</span> Required fields
            </Card.Text>
          </div>
          <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3 w-75">
              <Form.Row>
                <Form.Label className="fw-bold">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email *"
                  className={!errors.email ? "mb-2" : "error-border mb-2"}
                  ref={register(emailValidation)}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </Form.Row>

              <Card.Text>
                We'll use this email address to reply to your request
              </Card.Text>
            </Form.Group>

            <Form.Group className="mb-3 w-50">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Full Name Of Consumer <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="consumerName"
                    placeholder="Full Name Of Consumer *"
                    className={!errors.consumerName ? "mb-2" : "error-border"}
                    ref={register(textValidation)}
                  />
                  {errors.consumerName && (
                    <small className="text-danger">
                      {" "}
                      {errors.consumerName.message}{" "}
                    </small>
                  )}
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Are you a member of Pacific Service Credit Union?{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Yes"
                        name="areYouAMemberofPSCU"
                        value="Yes"
                        className="radio-label-item"
                        onClick={handlePSCUMember}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="No"
                        name="areYouAMemberofPSCU"
                        value="No"
                        className="radio-label-item"
                        onClick={handlePSCUMember}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            {isPSCUMember ? (
              <Form.Group className="mb-3 w-50">
                <Row>
                  <Col>
                    <Form.Label className="fw-bold">Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="accountNumber"
                      placeholder="Account Number"
                      className={
                        !errors.accountNumber ? "mb-2" : "error-border"
                      }
                      ref={register(numberValidation && { required: false })}
                    />
                    {errors.accountNumber && (
                      <small className="text-danger">
                        {" "}
                        {errors.accountNumber.message}{" "}
                      </small>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            ) : (
              ""
            )}

            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Are you enrolled in online or mobile banking?{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Yes"
                        name="onlineOrMobile"
                        value="Yes"
                        className="radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="No"
                        name="onlineOrMobile"
                        value="No"
                        className="radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3 w-75">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Primary Phone Number Of Consumer{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="consumerPhoneNumber"
                    placeholder="Primary Phone Number Of Consumer *"
                    className={
                      !errors.consumerPhoneNumber ? "mb-2" : "error-border"
                    }
                    ref={register({ required: true })}
                  />
                  {errors.consumerPhoneNumber && (
                    <small className="text-danger">
                      {" "}
                      {errors.consumerPhoneNumber.message}{" "}
                    </small>
                  )}
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3 w-50">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Home Address Of Consumer{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="consumerAddress"
                    placeholder="Home Address Of Consumer *"
                    className={
                      !errors.consumerAddress ? "mb-2" : "error-border"
                    }
                    ref={register({ required: true })}
                  />
                  {errors.consumerAddress && (
                    <small className="text-danger">
                      {" "}
                      {errors.consumerAddress.message}{" "}
                    </small>
                  )}
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Are you submitting a request on behalf of the Consumer?{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Yes"
                        name="requestFor"
                        value="Yes"
                        className="radio-label-item"
                        onClick={handleRequestFor}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="No"
                        name="requestFor"
                        value="No"
                        className="radio-label-item"
                        onClick={handleRequestFor}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            {requestSubmittedFor ? (
              <>
                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Full Name Of Consumer's Authorized Agent{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="AAFullName"
                        placeholder="Full Name Of Consumer's Authorized Agent"
                        className={!errors.AAFullName ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                      {errors.AAFullName && (
                        <small className="text-danger">
                          {" "}
                          {errors.AAFullName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Primary Phone Number Of Consumer's Authorized Agent{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="AAPhoneNumber"
                        placeholder="Phone Number Of Consumer's Authorized Agent"
                        className={
                          !errors.AAPhoneNumber ? "mb-2" : "error-border"
                        }
                        ref={register({ required: false })}
                      />
                      {errors.AAPhoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.AAPhoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Home Address Of Consumer's Authorized Agent{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="AAHomeAddress"
                        placeholder="Home Address Of Consumer's Authorized Agent"
                        className={
                          !errors.AAHomeAddress ? "mb-2" : "error-border"
                        }
                        ref={register({ required: false })}
                      />
                      {errors.AAHomeAddress && (
                        <small className="text-danger">
                          {" "}
                          {errors.AAHomeAddress.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Does your request include household information?{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Yes"
                        name="includeHouseholdInfo"
                        value="Yes"
                        className="radio-label-item"
                        onClick={householdHandle}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="No"
                        name="includeHouseholdInfo"
                        value="No"
                        className="radio-label-item"
                        onClick={householdHandle}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            {includeHouseHold ? (
              <>
                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Household Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="householdAddress"
                        placeholder="Household Address *"
                        className={
                          !errors.householdAddress ? "mb-2" : "error-border"
                        }
                        ref={register({ required: true })}
                      />
                      {errors.householdAddress && (
                        <small className="text-danger">
                          {" "}
                          {errors.householdAddress.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Householder Member's Full Name{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="householdMemFullName"
                        placeholder="Householder Member's Full Name *"
                        className={
                          !errors.householdMemFullName ? "mb-2" : "error-border"
                        }
                        ref={register({ required: true })}
                      />
                      {errors.householdMemFullName && (
                        <small className="text-danger">
                          {" "}
                          {errors.householdMemFullName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Household Member's Age{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="householdMemAge"
                        placeholder="Household Member's Age *"
                        className={
                          !errors.householdMemAge ? "mb-2" : "error-border"
                        }
                        ref={register({ required: true })}
                      />
                      {errors.householdMemAge && (
                        <small className="text-danger">
                          {" "}
                          {errors.householdMemAge.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {requestType === "access" ? (
              <Form.Group className="mb-3">
                <Row>
                  <Col>
                    <Form.Label className="fw-bold">
                      Please check all that apply to your request:{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Row className="ml-2">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Categories of Personal Information"
                          name="checkCategory"
                          value="Categories of Personal Information"
                          className="radio-label-item"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-2">
                      {" "}
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Categories or Sources of Collection"
                          name="checkCategory"
                          value="Categories or Sources of Collection"
                          className="radio-label-item"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-2">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Business or Commercial Purpose for Collecting or Selling Information"
                          name="checkCategory"
                          value="Business or Commercial Purpose for Collecting or Selling Information"
                          className="radio-label-item"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-2">
                      {" "}
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Categories of Third Parties with Whom Information is Shared"
                          name="checkCategory"
                          value="Categories of Third Parties with Whom Information is Shared"
                          className="radio-label-item"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-2">
                      {" "}
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Specific Pieces of Information"
                          name="checkCategory"
                          value="Specific Pieces of Information"
                          className="radio-label-item"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-center mt-4">
              <Recaptcha
                reCaptchaClick={handleReCaptchaClick}
                responseToken={getResponseToken}
              />
            </div>

            <Form.Group>
              <Row className="d-flex justify-content-center mt-5">
                <Col sm={12} md={10} lg={10} className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    style={{
                      width: "inherit",
                    }}
                    disabled={!formState.isValid || disableSubmit || validate}
                  >
                    Submit Request
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RequestForm;
