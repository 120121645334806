// function to create the paylod with necessary fields based on request
export default function getPayload({
  request,
  inputsData,
  configValues,
  recaptchaToken,
}) {
  for (let key in configValues.actions[request]) {
    if (key === "fields") {
      const dataFields = configValues.actions[request][key]
        .map((field) => {
          switch (field.label) {
            case "Email":
              return {
                value: inputsData["email"],
                label: field.label,
                _id: field._id,
              };

            case "Full Name Of Consumer":
              return {
                value: inputsData["consumerName"],
                label: field.label,
                _id: field._id,
              };
            case "Are you a member of Pacific Service Credit Union?":
              return {
                value: inputsData["areYouAMemberofPSCU"],
                label: field.label,
                _id: field._id,
              };
            case "Account Number":
              return {
                value: inputsData["accountNumber"],
                label: field.label,
                _id: field._id,
              };
            case "Are you enrolled in online or mobile banking?":
              return {
                value: inputsData["onlineOrMobile"],
                label: field.label,
                _id: field._id,
              };
            case "Primary Phone Number Of Consumer":
              return {
                value: inputsData["consumerPhoneNumber"],
                label: field.label,
                _id: field._id,
              };
            case "Home Address Of Consumer":
              return {
                value: inputsData["consumerAddress"],
                label: field.label,
                _id: field._id,
              };
            case "Full Name Of Consumer's Authorized Agent":
              return {
                value: inputsData["AAFullName"],
                label: field.label,
                _id: field._id,
              };
            case "Primary Phone Number Of Consumer's Authorized Agent":
              return {
                value: inputsData["AAPhoneNumber"],
                label: field.label,
                _id: field._id,
              };
            case "Home Address Of Consumer's Authorized Agent":
              return {
                value: inputsData["AAHomeAddress"],
                label: field.label,
                _id: field._id,
              };
            case "Does your request include household information?":
              return {
                value: inputsData["includeHouseholdInfo"],
                label: field.label,
                _id: field._id,
              };
            case "Household Address":
              return {
                value: inputsData["householdAddress"],
                label: field.label,
                _id: field._id,
              };
            case "Householder Member's Full Name":
              return {
                value: inputsData["householdMemFullName"],
                label: field.label,
                _id: field._id,
              };
            case "Household Member's Age":
              return {
                value: inputsData["householdMemAge"],
                label: field.label,
                _id: field._id,
              };
            case "Please check all that apply to your request:":
              return {
                value: inputsData["checkCategory"],
                label: field.label,
                _id: field._id,
              };
            case "Are you submitting a request on behalf of the Consumer?":
              return {
                value: inputsData["requestFor"],
                label: field.label,
                _id: field._id,
              };
            default:
              console.warn(`unknown field ${field.label}`);
          }
          return null;
        })
        .filter((field) => field);

      const primaryEmail = inputsData["email"] ? inputsData["email"] : "";

      const dataInputs = {
        data: {
          providedFields: dataFields,
          primaryEmail,
          providedRequests: [request],
          recaptchaToken,
        },
        meta: {
          id: `${configValues.settings}`,
          pageDataId: `${configValues._id}`,
          type: "dsar",
        },
      };
      return dataInputs;
    }
  }
}
