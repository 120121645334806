import React from "react";

function requestTypeIntro() {
  return (
    <div>
      <section className="container">
        <p className="text-justify mt-3">
          We respect the privacy and security of your personal information. To
          keep your information secure, we will only send you information that
          we can reasonably link to the email address you provided in this form
          and your results will not include certain sensitive financial
          information. You can always review our privacy practices in our{" "}
          <u>
            <a
              href="https://www.pacificservice.org/privacy"
              data-toggle="tooltip"
              data-placement="bottom"
              target="_blank"
              rel="noreferrer noopener"
              title="https://www.pacificservice.org/privacy"
            >
              Privacy Policy
            </a>
          </u>
          .
        </p>
      </section>
    </div>
  );
}

export default requestTypeIntro;
