import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { getService } from "../shared/components/restApi";
import Acknowledgement from "./Acknowledgement";
import SrrHeader from "../shared/components/SrrHeader";
import RequestForm from "../shared/components/RequestForm";
import RequestTypeIntro from "../shared/components/requestTypeIntro";

function Correction(props) {
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [configValues, setConfigValues] = useState({});
  const [requestId, setRequestId] = useState("");

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    const url = `${process.env.REACT_APP_PSCU_SRR_CONFIG_CORRECTION}`;
    let configOptions = {
      method: "GET",
      url,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});
          setConfigValues({ actions, settings: settingsId, _id: settingsId });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <div>
      <SrrHeader
        heading="Update Personal Information"
        breadcrumb_path1="Pacific Service Credit Union PRIVACY CENTER"
        breadcrumb_path2="Correction"
      />

      <Container className="pt-2">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <>
            <RequestTypeIntro />
            <RequestForm
              configValues={configValues}
              requestType="correction"
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
            />
          </>
        )}
      </Container>
    </div>
  );
}

export default Correction;
