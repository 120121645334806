import React from "react";
import RecaptchaCheck from "react-google-recaptcha";

function ReCaptcha(props) {
  const { reCaptchaClick, responseToken } = props;

  const verifyCallback = (response) => {
    if (response) {
      responseToken(response);
      reCaptchaClick(true);
    }
  };

  const expiredCallback = () => {
    reCaptchaClick(false);
  };

  return (
    <div className="m-recaptcha">
      <RecaptchaCheck
        sitekey="6Lf1iqYUAAAAAOjbtw1I0TrsGYpStLjwYFSzY4fX"
        onChange={verifyCallback}
        onExpired={expiredCallback}
        className="gg-recaptcha"
      />
    </div>
  );
}

export default ReCaptcha;
