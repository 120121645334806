import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import { Link } from "react-router-dom";
export default SubmitRequest;

function SubmitRequest() {
  const [isMinorBelow13, setIsMinorBelow13] = useState("");

  return (
    <>
      <SrrHeader
        heading="Submit Request"
        breadcrumb_path1="Pacific Service Credit Union Privacy Center"
        breadcrumb_path2="SUBMIT REQUEST"
      />

      <Container className="mt-4">
        <div
          id="minorAge"
          className="mb-3 mt-4"
          style={
            isMinorBelow13.length > 0
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <p>
            <strong>Are you a minor under the age of 13?</strong>{" "}
            <span className="text-danger">*</span>
          </p>

          <form>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="radio"
                  id="yes"
                  className="form-check-input text-success"
                  name="minorAge"
                  onClick={() => setIsMinorBelow13("Yes")}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="radio"
                  id="no"
                  className="form-check-input text-success"
                  name="minorAge"
                  onClick={() => setIsMinorBelow13("No")}
                />
                No
              </label>
            </div>
          </form>
        </div>
        {isMinorBelow13 === "" ? (
          ""
        ) : isMinorBelow13 === "Yes" ? (
          <section
            id="minorBelow13"
            style={{ display: "block" }}
            className="mt-4 submit-request"
          >
            <p>
              Individuals below 13 years of age cannot raise a request. Please
              email us at{" "}
              <u>
                <a
                  href="mailto: service@pacificservice.org"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="service@pacificservice.org"
                >
                  service@pacificservice.org
                </a>
              </u>
              .
            </p>
          </section>
        ) : (
          <section id="minorAbove13" className="mb-5">
            <h3>California Consumer Privacy Act</h3>
            <p>
              If you are a California resident, you can make certain requests
              regarding your personal information. We will fulfill each of these
              requests to the extent required by law.
            </p>
            <p>
              <strong>Access</strong> - You have the right to request
              information about the categories and specific pieces of personal
              data we have collected about you, as well as the categories of
              sources from which such information is collected, the purpose for
              collecting such information, and the categories of third parties
              with whom we share such information.
            </p>
            <p>
              <strong>Delete</strong> - You have the right to ask that we delete
              your personal information. Once we receive a request, we will
              delete the personal information (to the extent required by law) we
              hold about you as of the date of your request from our records and
              direct any service providers to do the same. Choosing to delete
              your personal information may impact your ability to use our
              websites and online features, including closure of your online
              account, and limit your use of functions that require your
              personal information.
            </p>
            <p>
              <strong>Correction</strong> - You have the right to update
              information about the categories and specific pieces of personal
              data we have collected about you, as well as the categories of
              sources from which such information is collected, the purpose for
              collecting such information, and the categories of third parties
              with whom we share such information.
            </p>

            <section id="myselfRequests" className="mt-4">
              <p>
                <u>
                  Please select the type of request you would like to submit:
                </u>
              </p>
              <div className="list d-flex flex-sm-row flex-column">
                <div className="me-3">
                  <Link className="selfLink" to={`/Access`} target="_blank">
                    <Button variant="primary"> Access </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`/Delete`} target="_blank">
                    <Button variant="primary" className="list-button">
                      {" "}
                      Delete{" "}
                    </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`/Correction`} target="_blank">
                    <Button variant="primary" className="list-button">
                      {" "}
                      Correction{" "}
                    </Button>
                  </Link>
                </div>
              </div>
            </section>
          </section>
        )}
      </Container>
    </>
  );
}
