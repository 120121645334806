import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import TrustCenter from "./components/TrustCenter";
import SubmitRequest from "./components/SubmitRequest";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import Access from "./components/Access";
import Delete from "./components/Delete";
import Correction from "./components/Correction";
import "./App.scss";

function App() {
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <div style={{ paddingBottom: "5rem" }}>
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={TrustCenter} />
            <Route path="/SubmitRequest" component={SubmitRequest} />
            <Route path="/Access" component={Access} />
            <Route path="/Delete" component={Delete} />
            <Route path="/Correction" component={Correction} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
